<template>
  <el-dialog
      title="选择文物类别"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <div v-show="diaLogType == 1">
      <div style="height: 450px;overflow: auto">
        <el-tree node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                 :props="defaultProps" @node-click="selRecord">
          <div slot-scope="{ node, data }">
            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
              <div class="omit">{{ node.label }}</div>
            </el-tooltip>
          </div>
        </el-tree>
      </div>
      <div class="text_center">
        <el-upload class="i_block m_r1 m_l1"
                   ref="upload"
                   :disabled="uploadAskObj.disabled"
                   action="action" :show-file-list="false"
                   :http-request="getImportData"
                   :before-upload="beforeUpload">
          <el-button size="small" type="primary">{{ butName }}
          </el-button>
        </el-upload>
      </div>
    </div>
    <div v-show="diaLogType == 2">
      <div v-loading="importLoading" element-loading-text="数据导入中"
           element-loading-background="rgba(255, 255, 255, 1)">
        <el-result icon="success" title="导入成功" :subTitle="'本次共导入'+importData.number+'条数据。'"
                   v-if="!importData.status"></el-result>
        <el-result icon="err or" title="导入失败" v-else></el-result>
        <div v-if="!importLoading && !importData.status" class="text_center">
          <div v-for="(item, index) in importData.data" :key="index">{{ item }}</div>
        </div>
      </div>
      <div v-if="!importLoading" slot="footer" class="text_center">
        <el-button size="small" @click="handleClose()">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload.vue";

export default {
  name: "importColl",
  components: {Upload},
  data() {
    return {
      dialogVisible: false,
      treeData: [],
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      uploadAskObj: {
        list: [],
        btnName: '导入藏品',
        fileType: ['xlsx', 'xls'],
        disabled: true,
      },

      feachUrl: '',
      diaLogType: 1,
      culturalRelicsCategory: '',
      importLoading: true, //文件状态
      importData: {}, //文件信息
      butName: '导入藏品',
    }
  },

  methods: {
    init(data, url, butName) {
      this.diaLogType = 1
      console.log(data)
      this.treeData = data
      if (url) {
        this.feachUrl = url
      } else {
        this.feachUrl = this.api.collection.excelImport
      }
      if (butName) {
        this.butName = butName
      }
      this.importLoading = true; //文件状态
      this.importData = {};
      this.dialogVisible = true
    },

    selRecord(selectData, data2, data3) {
      if (data2.parent.label != undefined) {
        this.culturalRelicsCategory = data2.parent.key
      }
      this.uploadAskObj.disabled = false
      this.culturalRelicsType = selectData.id
      //其它分类单写
      if (selectData.id == 6) {
        this.culturalRelicsType = ''
        this.culturalRelicsCategory = selectData.id
      }

    },

    handleClose() {
      this.culturalRelicsCategory = ''
      this.culturalRelicsType = ''
      this.uploadAskObj.disabled = true
      this.dialogVisible = false
    },

    beforeUpload(file) { // 上传文件之前钩子
      const type = file.name.split('.')[1]
      if (type !== 'xls' && type !== 'xlsx') {
        this.$message({type: 'error', message: '只支持xls和xlsx文件格式！'})
        return false
      }
      this.diaLogType = 2
    },

    // 导入
    getImportData(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('culturalRelicsCategory', this.culturalRelicsCategory)
      formData.append('culturalRelicsType', this.culturalRelicsType)
      this.$axios(this.feachUrl, formData, 'post').then(data => {
        if (data.status) {
          if (this.butName == '导入不可移动文物') {
            this.importData = data.data
            this.importData.status = !this.importData.status
            this.$set(this.importData, 'number', this.importData.data)
            this.$delete(this.importData, 'data')
            console.log(this.importData)
          } else {
            this.importData = data.data.data
          }
          this.importLoading = false
          if (this.butName == '导入不可移动文物') {
            if (data.status) {
              this.$emit('importFile', data.data.list)
            }
          } else {
            if (data.data.code) {
              this.$emit('importFile', data.data.list)
            }
          }
        } else {
          this.handleClose()
          this.$message.error(data.msg)
        }
      })
    },
  }
}
</script>

<style scoped>

</style>